export const yamatoEndpoints = {
	/** JavaScript を利用してトークンを発行する際のリクエスト仕様 */
	tokenGeneration: {
		TEST: "https://ptwebcollect.jp/test_gateway/token/js/embeddedTokenLib.js",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/token/js/embeddedTokenLib.js"
	},
	/** 3D セキュアまたはセキュリティコードによるトークン方式の通常決済行う際のリクエスト仕様（A08） */
	creditTokenRegistration: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditToken.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditToken"
	},
	/** トークン発行 API（A13）の利用にあたり、「エンドポイントトークン（EP トークン）」を発行する（A12） */
	epTokenAuthentication: {
		TEST: "https://ptwebcollect.jp/test_gateway/getEpToken.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/getEpToken"
	},
	/** JavaScript を実行できない環境（スマートフォンアプリなど）からトークン決済に必要なトークンを取得するための機能（A13） */
	tokenGenerationAPI: {
		TEST: "https://ptwebcollect.jp/test_gateway/getTraderToken.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/getTraderToken"
	},
	/** 3D セキュアを利用した決済で、返却された 3D セキュアの結果をセットして与信を行う際のリクエスト仕様（A09） */
	creditTokenRegistration3D: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditToken3D.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditToken3D"
	},
	/** お預かりしているクレジットカード情報を呼び出すリクエスト仕様（A03） */
	creditCardInfoInquiry: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditInfoGet.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditInfoGet"
	},
	/** お預かりしているクレジットカード情報を（カード会社名、有効期限、名義人）を更新する際のリクエスト仕様（A04） */
	creditCardInfoUpdate: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditInfoUpdate.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditInfoUpdate"
	},
	/** お預かりしているクレジットカード情報を削除する際のリクエスト仕様（A05） */
	creditCardInfoDelete: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditInfoDelete.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditInfoDelete"
	},
	/** 貴社システムより決済取消を行う際のリクエスト仕様（A06） */
	creditCancel: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditCancel.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditCancel"
	},
	/** 貴社システムより金額変更を行う際のリクエスト仕様（A07） */
	creditChangePrice: {
		TEST: "https://ptwebcollect.jp/test_gateway/creditChangePrice.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/creditChangePrice"
	},
	/** 貴社システムより再与信を行う際のリクエスト仕様（A11） */
	reAuth: {
		TEST: "https://ptwebcollect.jp/test_gateway/reAuth.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/reAuth"
	},
	/** 3D セキュアまたはセキュリティコードによるトークン方式の継続課金決済を行う際のリクエスト仕様（G06） */
	regularTokenRegistration: {
		TEST: "https://ptwebcollect.jp/test_gateway/regularToken.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/regularToken"
	},
	/** 3D セキュアを利用した決済で、返却された 3D セキュアの結果をセットして継続課金決済の与信を行う際のリクエスト仕様（G07） */
	regularTokenRegistration3D: {
		TEST: "https://ptwebcollect.jp/test_gateway/regularToken3D.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/regularToken3D"
	},
	/** 継続課金受注の取引情報等を確認する際のリクエスト仕様（G03） */
	regularInfoInquiry: {
		TEST: "https://ptwebcollect.jp/test_gateway/regularInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/regularInfo"
	},
	/** 継続課金の申込情報を更新する際のリクエスト仕様（G04） */
	regularInfoUpdate: {
		TEST: "https://ptwebcollect.jp/test_gateway/regularUpdate.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/regularUpdate"
	},
	/** 継続課金の申込情報の取消を行う際のリクエスト仕様（G05） */
	regularInfoDelete: {
		TEST: "https://ptwebcollect.jp/test_gateway/regularDelete.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/regularDelete"
	},
	/** Apple Pay を利用した決済を行う際のリクエスト仕様（A10） */
	applePayRegistration: {
		TEST: "https://ptwebcollect.jp/test_gateway/applePay.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/applePay"
	},
	/** コンビニ（セブン-イレブン）を利用した決済を行う際のリクエスト仕様（B01） */
	convenienceStoreSevenEleven: {
		TEST: "https://ptwebcollect.jp/test_gateway/cvs1.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/cvs1"
	},
	/** コンビニ（ファミリーマート）を利用した決済を行う際のリクエスト仕様（B02） */
	convenienceStoreFamilyMart: {
		TEST: "https://ptwebcollect.jp/test_gateway/cvs2.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/cvs2"
	},
	/** コンビニ（ローソン等）を利用した決済を行う際のリクエスト仕様（B03, B05, B06） */
	convenienceStoreLawson: {
		TEST: "https://ptwebcollect.jp/test_gateway/cvs3.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/cvs3"
	},
	/** クレジットカード払いの与信完了後、またはコンビニ等での入金完了した受注に対し、送り状番号を登録する際のリクエスト仕様（E01） */
	shipmentEntry: {
		TEST: "https://ptwebcollect.jp/test_gateway/shipmentEntry.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/shipmentEntry"
	},
	/** 登録した送り状番号の取消を行う際のリクエスト仕様（E02） */
	shipmentCancel: {
		TEST: "https://ptwebcollect.jp/test_gateway/shipmentCancel.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/shipmentCancel"
	},
	/** クレジットカード払いのオプションサービス機能である予約販売において、設定した出荷予定日を変更する際のリクエスト仕様（E03） */
	changeDate: {
		TEST: "https://ptwebcollect.jp/test_gateway/changeDate.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/changeDate"
	},
	/** 入金情報等、受注の取引情報を確認する際のリクエスト仕様（E04） */
	tradeInfoInquiry: {
		TEST: "https://ptwebcollect.jp/test_gateway/tradeInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/tradeInfo"
	},
	/** 各キャリアを利用した決済を行う際のリクエスト仕様（J01） */
	carrierSettle: {
		TEST: "https://ptwebcollect.jp/test_gateway/carrierSettle.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/carrierSettle"
	},
	/** docomo/SoftBank を利用した会員決済のために会員申込を行う際のリクエスト仕様（J02） */
	carrierSignUpMem: {
		TEST: "https://ptwebcollect.jp/test_gateway/carrierSignUpMem.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/carrierSignUpMem"
	},
	/** docomo/SoftBank を利用した会員決済を行う際のリクエスト仕様（J03） */
	carrierSettleMem: {
		TEST: "https://ptwebcollect.jp/test_gateway/carrierSettleMem.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/carrierSettleMem"
	},
	/** 貴社システムより決済取消を行う際のリクエスト仕様（J04） */
	carrierSettleCancel: {
		TEST: "https://ptwebcollect.jp/test_gateway/carrierSettleCancel.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/carrierSettleCancel"
	},
	/** 貴社システムより金額変更を行う際のリクエスト仕様（J05） */
	carrierChangePrice: {
		TEST: "https://ptwebcollect.jp/test_gateway/carrierChangePrice.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/carrierChangePrice"
	},
	/** 不正利用判定のため購入者様の住所情報等の照会を行う際のリクエスト仕様（K01） */
	fraudDetectionAddress: {
		TEST: "https://ptwebcollect.jp/test_gateway/fraudDetectionAddress.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/fraudDetectionAddress"
	},
	/** ご利用のグローバル IP アドレス照会を行う際のリクエスト（テスト環境のみでの提供） */
	ipAddressInquiry: {
		TEST: "https://ptwebcollect.jp/test_gateway/traderIp.api",
		PRODUCTION: "-" // テスト環境のみでの提供
	},
	/** 承諾申込として決済された取引を基に、複数の決済を1つのファイルとしてまとめて決済する際のリクエスト仕様（A20） */
	fileSettleOrderInfo: {
		TEST: "https://ptwebcollect.jp/test_gateway/fileSettleOrderInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/fileapi/fileSettleOrderInfo"
	},
	/** ファイル決済登録の結果を確認する際のリクエスト仕様（A21） */
	fileSettleDetailInfo: {
		TEST: "https://ptwebcollect.jp/test_gateway/fileSettleDetailInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/fileapi/fileSettleDetailInfo"
	},
	/** 複数の決済を1つのファイルとしてまとめて出荷情報登録する際のリクエスト仕様（E20） */
	fileShippingOrderInfo: {
		TEST: "https://ptwebcollect.jp/test_gateway/fileShippingOrderInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/fileapi/fileShippingOrderInfo"
	},
	/** ファイル出荷情報登録の結果を確認する際のリクエスト仕様（E21） */
	fileShippingDetailInfo: {
		TEST: "https://ptwebcollect.jp/test_gateway/fileShippingDetailInfo.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/fileapi/fileShippingDetailInfo"
	},
	/** PayPay を利用した通常決済を行う際のリクエスト仕様（L01） */
	payPaySettle: {
		TEST: "https://ptwebcollect.jp/test_gateway/paypaySettle.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/paypaySettle"
	},
	/** 貴社システムより決済取消を行う際のリクエスト仕様（L02） */
	payPaySettleCancel: {
		TEST: "https://ptwebcollect.jp/test_gateway/paypaySettleCancel.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/paypaySettleCancel"
	},
	/** 貴社システムより金額変更を行う際のリクエスト仕様（L03） */
	payPayChangePrice: {
		TEST: "https://ptwebcollect.jp/test_gateway/paypayChangePrice.api",
		PRODUCTION: "https://api.kuronekoyamato.co.jp/api/paypayChangePrice"
	}
};
