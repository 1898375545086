/**
 * 利用可能な決済代行
 *
 * `$Enums.CreditAgency`と一致させてください
 */
export enum CreditAgency {
	/** ヤマトフィナンシャル */
	YAMATO_WEB_COLLECT = "YAMATO_WEB_COLLECT",
	ZEUS = "ZEUS",
	SMBC_GMO = "SMBC_GMO",
	GMO = "GMO"
}

export enum ThreeDomainSecureAuthType {
	REDIRECT = "REDIRECT",
	POP = "POP",
	IFRAME = "IFRAME"
}
