interface DateInput {
	year: {
		/** フォーマットする値 */
		number: number | string;
		/**
		 * フォーマット後の長さ
		 * - 2: 引数が4桁の場合後ろの２桁, 2桁の場合そのまま
		 * - 4: 引数の数字をそのままStringにする
		 */
		length: 2 | 4;
	};
	month: {
		/**  フォーマットする値 */
		number: string | number;
		/** 1桁の場合に先頭の0埋めをする場合`true` */
		padding?: boolean;
	};
}

/** フォーマット後の年/月情報 */
interface DateOutput {
	/** フォーマットされた年 */
	year: string;
	/** フォーマットされtあ月 */
	month: string;
}

/**
 * 引数の値を元に、年、月の一般的なフォーマットを行う
 * @param year 年情報
 * @param month 月情報
 * @returns フォーマットされた年/月
 */
export const formatExpire = ({ year, month }: DateInput): DateOutput => {
	// 年のフォーマット処理
	const formattedYear = typeof year.number === "number" ? year.number.toString() : year.number;
	const yearString = year.length === 2 ? formattedYear.slice(-2) : formattedYear;

	// 月のフォーマット処理
	let formattedMonth = typeof month.number === "number" ? month.number.toString() : month.number;
	if (month.padding !== false && formattedMonth.length === 1) {
		formattedMonth = "0" + formattedMonth; // 1桁の場合、0埋めする
	}

	return {
		year: yearString,
		month: formattedMonth
	};
};
