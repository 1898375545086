import {
	Code,
	CreditCardError,
	CreditCardJsInitResponse,
	PaymentSystemError,
	PaymentSystemErrorObject,
	PaymentSystemReturnStatus,
	TokenError
} from "../../../types/v1/credit_card";
import { CreditAgency } from "../../../types/v1/credit_card/enum";

export enum CommonErrorCode {
	MAINTENANCE = "SCB000001",
	COMMUNICATION_FAILURE = "SCB000002",
	/** 決済代行のシステムエラー */
	SYSTEM_ERROR = "SCB000003",
	POST_METHOD_REQUIRED = "SCB000004",
	FUNCTION_DIVISION_REQUIRED = "SCB000005",
	INVALID_FUNCTION_DIVISION = "SCB000006",
	CONCURRENT_PROCESSING_OVERFLOW = "SCB000007",
	INVALID_MERCHANT_CODE = "SCB000008",
	API_REGISTRATION_REQUIRED = "SCB000009",
	/** タイムアウト */
	TIME_OUT = "SCB000010",
	/** 決済システム通信中にネットワーク関連のエラーが発生した */
	NETWORK_ERROR = "SCB999993",
	/** 決済システム認証エラー */
	UN_AUTHORIZED = "SCB999994",
	/** 存在しない決済代行が選択されている */
	UNKNOWN_AGENCY = "SCB999995",
	/** 配信jsの`init`関数が実行されていない */
	NOT_SYSTEM_INIT = "SCB999996",
	/** 決済設定の取得に失敗した */
	GET_PAYMENT_SYSTEM_SETTINGS_FAILURE = "SCB999997",
	/** 不明なエラー */
	UNKNOWN_ERROR = "SCB999999"
}

/** 決済システム固有の共通エラー */
export const commonUniqueErrors: PaymentSystemError[] = [
	{
		code: CommonErrorCode.NETWORK_ERROR,
		item: "",
		description: "通信中に不明なエラーが発生しました。ネットワークを確認してください。"
	},
	{
		code: CommonErrorCode.UN_AUTHORIZED,
		item: "apiKey, authValue",
		description: "api認証に失敗しました。認証キーが正しいか確認してください。"
	},
	{
		code: CommonErrorCode.UNKNOWN_AGENCY,
		item: "window.LeghornPayment.getSettings().agency",
		description: "存在しない決済代行が設定されています。"
	},
	{
		code: CommonErrorCode.NOT_SYSTEM_INIT,
		item: "window.LeghornPayment.init",
		description: "初期化が完了していません, `init`関数を実行してください。"
	},
	{
		code: CommonErrorCode.GET_PAYMENT_SYSTEM_SETTINGS_FAILURE,
		item: "",
		description: "決済設定の取得に失敗しました。"
	}
];

/**
 * 共通のエラーマッピング
 *
 * @remarks
 * 共通エラーコード区分：`SCB` Script CreditCard Common
 */
export const commonErrors: TokenError[] = [
	{
		code: CommonErrorCode.MAINTENANCE,
		item: "",
		description: "メンテナンス中です",
		ZEUS: ["88888888"],
		YAMATO_WEB_COLLECT: ["Z010000000"]
	},
	{
		code: CommonErrorCode.COMMUNICATION_FAILURE,
		item: "",
		description: "通信に失敗しました。",
		ZEUS: ["90100100"],
		YAMATO_WEB_COLLECT: ["Z012000005", "Z012000011"]
	},
	{
		code: CommonErrorCode.SYSTEM_ERROR,
		item: "",
		description: "システムエラーが発生しました。",
		ZEUS: ["99999999"],
		YAMATO_WEB_COLLECT: ["Z019999999"],
		GMO: ["901"]
	},
	{
		code: CommonErrorCode.POST_METHOD_REQUIRED,
		item: "",
		description: "POST メソッドにてリクエストしてください。",
		ZEUS: ["02030105"],
		YAMATO_WEB_COLLECT: ["Z012000001"]
	},
	{
		code: "SCB000005",
		item: "",
		description: "機能区分をセットしてください。",
		YAMATO_WEB_COLLECT: ["Z012000002"]
	},
	{
		code: "SCB000006",
		item: "",
		description: "正しい機能区分をセットしてください。",
		YAMATO_WEB_COLLECT: ["Z012000003"]
	},
	{
		code: "SCB000007",
		item: "",
		description: "同時処理数オーバーです。時間を置いて再度実行してください。",
		YAMATO_WEB_COLLECT: ["Z012000006"],
		GMO: ["902"]
	},
	{
		code: "SCB000008",
		item: "",
		description: "正しい加盟店コードをセットしてください。",
		YAMATO_WEB_COLLECT: ["Z012000007"]
	},
	{
		code: "SCB000009",
		item: "",
		description:
			"API の利用登録がありません。セットされた加盟店コードが正しいか、API の利用登録が行なわれているか確認してください。",
		YAMATO_WEB_COLLECT: ["Z012000008"]
	},
	{
		code: "SCB000010",
		item: "response.settings.shop_id",
		description: "ショップIDが不正です。管理画面を確認し、正しい値をセットしてください。",
		GMO: ["160"]
	},
	{
		code: "SCB000011",
		item: "response.settings.shop_id",
		description:
			"ショップIDのフォーマットが不正です。管理画面を確認し、正しい値をセットしてください。",
		GMO: ["161"]
	},
	{
		code: "SCB000010",
		item: "response.settings.shop_id",
		description:
			"ショップIDまたは公開鍵ハッシュ値が不正です。管理画面を確認し、正しい値をセットしてください。",
		GMO: ["180"]
	}
];

/**
 * 決済代行ごとにマッピングされたエラーオブジェクトの中から該当するエラーコードを探し、決済システム固有のエラーオブジェクトにして返す
 * @param errorCords 決済代行が投げてきたエラー
 * @param agency エラーを受け取った時に利用中の決済代行
 * @param unknownErrorCord マッピングされていない時に利用するエラーコード
 * @param errorMappedArray マッピングされたエラーコードのオブジェクト配列（処理ごとのも）
 * @param paymentSystemErrorCodes 決済システム固有のエラーコードを直接指定する場合
 * @returns 決済システム固有のエラーオブジェクト配列
 */
export const getErrorObjects = (
	errorCords: Code[],
	unknownErrorCord: string,
	errorMappedArray: CreditCardError[],
	paymentSystemUniqueErrors: PaymentSystemError[],
	settings?: CreditCardJsInitResponse,
	paymentSystemErrorCodes?: string[]
): PaymentSystemError[] => {
	const findErrors: Code[] = [];

	// GMOのエラーコードがstringだったりnumberだったりバラバラなので、stringに変換
	if (settings?.agency === CreditAgency.GMO) {
		errorCords = errorCords.map(e => String(e));
	}

	/** マッピングされたエラーコードの対応するエラーオブジェクト配列 */
	const errors: PaymentSystemError[] = settings
		? errorMappedArray
				.filter(errorMappedObject => {
					const matchedErrorCords = errorCords?.filter(errorCord =>
						errorMappedObject[settings.agency]?.includes(errorCord)
					);
					findErrors.push(...matchedErrorCords);
					return matchedErrorCords.length > 0;
				})
				.map(error => {
					// .mapのスコープ内で使用する
					const matchedErrorCords = errorCords?.filter(errorCord =>
						error[settings.agency]?.includes(errorCord)
					);

					return {
						code: error.code,
						item: error.item,
						description: error.description,
						origin_code: matchedErrorCords[0]
					};
				}) || []
		: [];

	// 決済システムのエラーコードが直接指定されている場合
	[...paymentSystemUniqueErrors, ...errorMappedArray]
		.filter(errorMappedObject => {
			const matchedErrorCords = paymentSystemErrorCodes?.filter(
				designateError =>
					// 直指定のエラーコードが存在しかつ、`errors`にない場合のみ
					errorMappedObject.code === designateError &&
					!errors.map(e => e.code).includes(designateError)
			);
			if (matchedErrorCords) {
				findErrors.push(...matchedErrorCords);
				return matchedErrorCords.length > 0;
			}
		})
		.forEach(error => {
			errors.push({ code: error.code, item: error.item, description: error.description });
		});

	/** マッピングされていないエラーコードの配列 */
	const unmatchedErrorCords = errorCords.filter(
		errorCord => !findErrors.some(findError => findError === errorCord)
	);

	// 不明なエラーは決済代行のエラーをそのままフロントに返す
	// `item`は決済代行なので、それと`description`のエラーコードを突き合わせてください
	for (const unmatchedErrorCord of unmatchedErrorCords) {
		errors.push({
			code: unknownErrorCord,
			item: settings?.agency || "",
			description: `不明なエラーが発生しました： ${unmatchedErrorCord}`
		});
	}

	return errors;
};

/**
 * 共通のエラーを取得する
 * @param errorCords 共通処理で発生した決済代行からのエラーコード配列
 * @param settings アカウントの設定
 * @param paymentSystemErrorCodes 決済システム固有のエラーコード
 * @returns 決済システム固有のエラーオブジェクト配列
 */
export const getCommonErrorObjects = (
	errorCords: string[],
	settings?: CreditCardJsInitResponse,
	paymentSystemErrorCodes?: CommonErrorCode[]
) => {
	return getErrorObjects(
		errorCords,
		CommonErrorCode.UNKNOWN_ERROR,
		commonErrors,
		commonUniqueErrors,
		settings,
		paymentSystemErrorCodes
	);
};

/** 初期化していない時のエラーオブジェクト生成 */
export const notInitError = (): PaymentSystemErrorObject => {
	return {
		status: PaymentSystemReturnStatus.FAILURE,
		errors: getCommonErrorObjects([], undefined, [CommonErrorCode.NOT_SYSTEM_INIT])
	};
};
