type ElementAttributes = {
	[key: string]: string;
};

/**
 * idの要素を検索し、存在しない場合は新規作成後、挿入
 * 存在する場合は削除後に挿入する
 * @param id 検索するid要素
 * @param tagName createElementする要素の種類
 * @param attributes 設定する属性
 * @param textContent 設定するtextContent
 */
export const insertOrUpdateElement = (
	id: string,
	tagName: string = "div",
	attributes?: ElementAttributes,
	textContent?: string
) => {
	// 既存の要素を確認
	let element = document.getElementById(id);
	if (element) {
		console.log(`id="${id}"を削除して再挿入`);
		element.remove();
	} else {
		console.log(`id="${id}"を新規作成`);
	}

	// 新しい要素を作成
	element = document.createElement(tagName);

	// 属性を設定
	if (attributes) {
		for (const key in attributes) {
			element.setAttribute(key, attributes[key]);
		}
	}

	// textContentがある場合
	if (textContent) element.textContent = textContent;

	element.id = id;
	document.body.appendChild(element);
};
