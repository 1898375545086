import { Builder, parseStringPromise } from "xml2js";

/**
 * `xml` を `object` に変換する
 *
 * プロジェクト上で共有して扱えるように、`xml2js` の `parseStringPromise` を以下のように拡張
 *
 * - `xml` 上の子要素はパースされる時に順序を守る
 * - `key` に対して要素が存在しない場合は `null` とする
 * - タグの属性は `key` 名を `attribute` とする
 * - タグのテキストは `key` 名を `text` とする
 * - 型引数を受け入れ、`return` を任意の定義済みの値に変更可能に
 *
 * @param xml パースするxml
 * @returns パースされたオブジェクト
 */
export const xmlParser = async <T>(xml: string): Promise<T | null> => {
	try {
		const result = (await parseStringPromise(xml, {
			preserveChildrenOrder: true, // 複数の要素はxmlの順序を守る
			emptyTag: () => null, // 値なしをnullとする
			explicitArray: false, // 配列として扱わない
			ignoreAttrs: false, // 属性を無視しない
			// mergeAttrs: true, // 属性をマージ
			charkey: "_", // テキストノードのキー
			attrkey: "attribute" // 属性のキー
		})) as T;

		return result;
	} catch (error) {
		console.error("Error parsing XML:", error);
		return null;
	}
};

/**
 * オブジェクトをXMLに変換する関数
 *
 * 属性が必要な場合`$`をkeyにして、そのvalueに属性をオブジェクトで記載してください
 *
 * `$`を利用してその要素にテキストが必要な場合、`_`で定義してください
 *
 * @param obj - 変換するオブジェクト
 * @returns 変換されたXML文字列
 * @example
 * ```ts
 * const data = {
 *   request: {
 *     $: { service: "secure_link_3d", action: "enroll" },
 *     authentication: { clientip: "9999999999", key: "1AAABBBCCCDDDEEEFFFGGGHHH" },
 *     token_key: "XXXXXXXXXXXXXXXXXXXX",
 *     payment: { amount: "1000", count: "01" },
 *     user: {
 *       telno: { _: "01234567890", $: { validation: "strict" } },
 *       email: { _: "xxxxxx@sbi-finsol.co.jp", $: { language: "japanese" } }
 *     },
 *     uniq_key: { sendid: "1234567890abcdefghij", sendpoint: "1234567890abcdefghij" },
 *     use_3ds2_flag: "1"
 *   }
 * };
 *
 * const xml = objectToXml(data);
 * console.log(xml);
 * ```
 * // 出力されるXML
 * ```xml
 *  <?xml version="1.0" encoding="UTF-8"?>
 *  <request service="secure_link_3d" action="enroll">
 *    <authentication>
 *      <clientip>9999999999</clientip>
 *      <key>1AAABBBCCCDDDEEEFFFGGGHHH</key>
 *    </authentication>
 *    <token_key>XXXXXXXXXXXXXXXXXXXX</token_key>
 *    <payment>
 *      <amount>1000</amount>
 *      <count>01</count>
 *    </payment>
 *    <user>
 *      <telno validation="strict">01234567890</telno>
 *      <email language="japanese">xxxxxx@sbi-finsol.co.jp</email>
 *    </user>
 *    <uniq_key>
 *      <sendid>1234567890abcdefghij</sendid>
 *      <sendpoint>1234567890abcdefghij</sendpoint>
 *    </uniq_key>
 *    <use_3ds2_flag>1</use_3ds2_flag>
 *  </request>
 * ```
 */
export const objectToXml = (obj: object): string => {
	const builder = new Builder({
		headless: false, // XML宣言を含める
		renderOpts: { pretty: true, indent: "  ", newline: "\n" } // 整形
	});

	return builder.buildObject(obj);
};
