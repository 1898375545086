import axios from "axios";
import {
	CardInfo,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	CreditCardJsInitResponse,
	GetTokenSuccessResponse,
	GetTokenFailureResponse,
	TokenError,
	PaymentSystemReturnStatus
} from "../../../types/v1/credit_card";
import {
	CreditCardZeusInitData,
	ZeusGetTokenAction,
	ZeusGetTokenNewCardRequest,
	ZeusGetTokenQuickRequest,
	ZeusGetTokenResponse,
	ZeusGetTokenSuccessResponse
} from "../../../types/v1/credit_card/zeus";
import { objectToXml, xmlParser } from "../../common/xmlTransform";
import { zeusEndpoints } from "../../endpoint/zeus";
import { getTokenError, unknownTokenError } from "../error/tokenError";
import { maskSecurityCode, maskCardNumber } from "../util";
import { formatExpire } from "../../common/formatExpire";

/**
 * トークン取得用のpost-bodyを作成する（xmlではない）
 * @param cardInfo カード情報（サーバーで保持しないように）
 * @param settings 決済システムの設定内容
 * @returns post用のbody
 */
const createPostData = (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardZeusInitData>,
	action: ZeusGetTokenAction
): ZeusGetTokenNewCardRequest | ZeusGetTokenQuickRequest => {
	if (action === ZeusGetTokenAction.NEW_CARD) {
		const format = formatExpire({
			year: { number: cardInfo.expires.year, length: 4 },
			month: { number: cardInfo.expires.month, padding: true }
		});

		const newCardRequest: ZeusGetTokenNewCardRequest = {
			request: {
				$: { service: "token", action: ZeusGetTokenAction.NEW_CARD },
				authentication: { clientip: settings.client_ip },
				card: {
					...(cardInfo.security_code ? { cvv: cardInfo.security_code } : {}),
					number: cardInfo.number,
					expires: { year: format.year, month: format.month },
					name: cardInfo.name
				}
			}
		};

		return newCardRequest;
	} else if (action === ZeusGetTokenAction.QUICK) {
		const quickRequest: ZeusGetTokenQuickRequest = {
			request: {
				$: { service: "token", action: ZeusGetTokenAction.QUICK },
				authentication: { clientip: settings.client_ip },
				card: {
					...(cardInfo.security_code ? { cvv: cardInfo.security_code } : {})
				}
			}
		};

		return quickRequest;
	} else {
		throw new Error(`存在しないactionが指定されています: ${String(action)}`);
	}
};

/**
 * トークン取得が正常成功した場合のレスポンス用オブジェクトを作成する
 * @param cardInfo カード情報
 * @param response zeusからのレスポンス
 * @returns 認証成功オブジェクト
 */
const crateSuccessResponse = (
	cardInfo: CardInfo,
	response: ZeusGetTokenSuccessResponse
): GetTokenSuccessResponse => {
	return {
		status: PaymentSystemReturnStatus.SUCCESS,
		token: response.result.token_key,
		masked_security_code: maskSecurityCode(cardInfo.security_code),
		number: maskCardNumber(cardInfo.number),
		name: cardInfo.name,
		expires: { year: cardInfo.expires.year, month: cardInfo.expires.month },
		brand: cardInfo.brand
	};
};

/**
 * トークン取得に失敗した場合のレスポンス用オブジェクトを作成する
 * @param errors エラー内容（決済システム独自のもの）
 * @returns 認証失敗オブジェクト
 */
const createFailureResponse = (errors: TokenError[]): GetTokenFailureResponse => {
	return { status: PaymentSystemReturnStatus.FAILURE, errors };
};

/**
 * カード情報を設定を元にtokenを取得する
 * @param cardInfo カード情報（サーバーで保持しないように）
 * @param onSuccess token発行成功時のコールバック
 * @param onFailure token発行失敗時のコールバック
 * @param settings 決済システム設定情報
 */
export const creditZeusGetToken = async (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardZeusInitData>,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure
) => {
	try {
		const request = createPostData(
			cardInfo,
			settings,
			// カードidが指定されている場合はQUICKでトークンを撮りに行く
			cardInfo.card_id ? ZeusGetTokenAction.QUICK : ZeusGetTokenAction.NEW_CARD
		);

		const response = await axios.post<string>(
			zeusEndpoints.token[settings.environment],
			objectToXml(request),
			{ headers: { "Content-Type": "application/xml", Accept: "application/xml" } }
		);

		/** トークン取得結果（xmlレスポンスをパースしたオブジェクト） */
		const data = (await xmlParser<ZeusGetTokenResponse>(response.data))?.response;

		// xmlのパースがうまくいかなかった
		if (!data) {
			const failureResponse = createFailureResponse(getTokenError(["Z012000005"], settings));
			if (onFailure) onFailure(failureResponse);

			return failureResponse;
		}

		// zeusからの返り値を元に分岐
		if (data.result.status === "success") {
			console.log(data);
			const successResponse = crateSuccessResponse(cardInfo, { result: data.result });

			if (onSuccess) onSuccess(successResponse);
			window.LeghornPayment.card = successResponse;
			return successResponse;
		} else {
			console.error(data);
			const failureResponse = createFailureResponse(
				getTokenError([data.result.code], settings)
			);

			if (onFailure) onFailure(failureResponse);
			window.LeghornPayment.card = failureResponse;
			return failureResponse;
		}
	} catch (e) {
		// 決済システムの不明エラーを作って返す
		console.error(e);
		const failureResponse = createFailureResponse(unknownTokenError(e));

		if (onFailure) onFailure(failureResponse);
		window.LeghornPayment.card = failureResponse;
		return failureResponse;
	}
};
