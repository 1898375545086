import { CreditAgency } from "./enum";

export interface WebcollectTokenLib {
	createToken: (
		createTokenInfo: YamatoGetTokenInfo,
		successCallback: (
			res: WebcollectTokenLibCreateTokenSuccessResponse
		) => void | Promise<void>,
		failureCallback: (res: WebcollectTokenLibCreateTokenFailureResponse) => void | Promise<void>
	) => Promise<void>;
}

export enum AuthDiv {
	/** 3Dセキュアあり、セキュリティコード認証なし */
	SECURE_WITHOUT_SECURITY_CODE = "1",
	/** 3Dセキュアなし、セキュリティコード認証あり */
	NO_SECURE_WITH_SECURITY_CODE = "2",
	/** 3Dセキュアあり、セキュリティコード認証あり */
	SECURE_WITH_SECURITY_CODE = "3"
}

/** 初期化成功時のヤマトクレカ向け追加のレスポンス */
export interface CreditCardYamatoInitData {
	agency: CreditAgency.YAMATO_WEB_COLLECT;
	trader_code: number;
	auth_dev: AuthDiv;
	access_key: string;
}

interface YamatoGetTokenBaseInfo {
	/** 加盟店コード */
	traderCode: number;
	/** 認証区分 */
	authDiv: AuthDiv;
	/** サービス区分
	 * - 00: 通常
	 * - 01: オプション受注（カード登録、継続課金、予約販売など）
	 */
	optServDiv: "00" | "01";
	/** トークン取得用チェックサム（アクセスキーとサービス区分を結合してハッシュ化したもの） */
	checkSum: string;
	/** カード番号 */
	cardNo?: number | string;
	/** カード名義 */
	cardOwner: string;
	/** カード有効期限 */
	cardExp: string;
	/** cvv */
	securityCode?: string;
}

interface YamatoGetTokenOptionInfo {
	/** サービス区分
	 * - 00: 通常
	 * - 01: オプション受注（カード登録、継続課金、予約販売など）
	 */
	optServDiv: "01";
	/** 会員ID */
	memberId: string;
	/** カード認証キー */
	authKey: string;
	/**
	 * カード保管番号
	 * 会員の紐付きに関係なく毎回作成しているので、常に1でOK
	 */
	cardKey?: "1" | "2" | "3";
	/** カード最終利用日, 登録済みカード利用の場合にセット（「A03: お預かりカード参照」で確認した値, YYYYMMDDhhmmss） */
	lastCreditDate?: string;
}

/** `createToken`関数の第一引数 */
export type YamatoGetTokenInfo =
	| (YamatoGetTokenBaseInfo & { optServDiv: "00" })
	| (YamatoGetTokenBaseInfo & YamatoGetTokenOptionInfo);

/** `createToken`関数のsuccessCallbackに渡されるオブジェクト */
export interface WebcollectTokenLibCreateTokenSuccessResponse {
	/** 取得成功 */
	returnCode: "0";
	/** トークン */
	token: string;
}

/** `createToken`関数のfailureCallbackに渡されるオブジェクト */
export interface WebcollectTokenLibCreateTokenFailureResponse {
	/** 取得失敗 */
	returnCode: "1";
	/** エラー内容 */
	errorInfo: {
		/** エラーコード */
		errorCode: string;
		/** エラー発生箇所 */
		errorItem: string;
		/** エラー詳細 */
		errorMsg: string;
	}[];
}
