export const zeusEndpoints = {
	/** JavaScript を利用してトークンを発行する際のリクエスト仕様 */
	token: {
		TEST: "https://linkpt.cardservice.co.jp/cgi-bin/token/token.cgi",
		PRODUCTION: "https://linkpt.cardservice.co.jp/cgi-bin/token/token.cgi"
	},
	/**	EMV 3-Dセキュア認証判定リクエストXML仕様 */
	enrolReq: {
		TEST: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi",
		PRODUCTION: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi"
	},
	paReq: {
		TEST: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi",
		PRODUCTION: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi"
	},
	authReq: {
		TEST: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi",
		PRODUCTION: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi"
	},
	payReq: {
		TEST: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi",
		PRODUCTION: "https://linkpt.cardservice.co.jp/cgi-bin/secure/api.cgi"
	}
};

export const zeusJsFileEndpoints = {
	withToken: {
		withSecurityCode: "https://linkpt.cardservice.co.jp/api/token/2.0/zeus_token_cvv2.js",
		withoutSecurityCode: "https://linkpt.cardservice.co.jp/api/token/2.0/zeus_token2.js"
	},
	withoutToken: {
		withSecurityCode: "https://linkpt.cardservice.co.jp/api/3ds2/3ds-web-wrapper.js",
		withoutSecurityCode: "https://linkpt.cardservice.co.jp/api/3ds2/3ds-web-wrapper.js"
	}
};
