import { CardNumber } from "../../types/v1/credit_card";

/**
 * 引数の番号をマスクする
 * 上２桁、下４桁はそのまま
 * @param cardNumber マスクする番号
 * @returns マスクされたカード番号（桁数は引数の桁数に準じる）
 * @example
 * const cardNumber = 4542012301230123
 * return {
 * 	prefix: "45",
 * 	suffix: "0123",
 * 	masked: "45********0123"
 * }
 */
export const maskCardNumber = (cardNumber: string | number): CardNumber => {
	const cardStr = cardNumber.toString();
	const firstFour = cardStr.slice(0, 2); // 初めの2桁
	const maskedSection = cardStr.slice(2, -4).replace(/./g, "*"); // 中間部分をマスク
	const lastFour = cardStr.slice(-4); // 下4桁

	return { prefix: firstFour, suffix: lastFour, masked: firstFour + maskedSection + lastFour };
};

/**
 * 引数のセキュリティーコードをマスクする
 * @param securityCode マスクするセキュリティーコード
 * @returns マスクされたセキュリティーコード
 * @example
 * *** （cvv3桁の場合）
 * **** （cvvが4桁の場合）
 */
export const maskSecurityCode = (securityCode: string | number | undefined): string | undefined => {
	if (securityCode) {
		const securityCodeStr = securityCode.toString();
		return securityCodeStr.replace(/./g, "*");
	} else {
		return undefined;
	}
};
