export enum ScriptLoadedType {
	/** 読み込み済み */
	LOADED = "loaded",
	/** 初回読み込み */
	FIRST_TIME = "first_time"
}

/**
 * 引数のURLを`script text/javascript`で取得し、html上に埋め込む
 *
 * すでに存在した場合、再取得は行わない
 * @param src 読み込む対象のurl（絶対パス）
 * @param appendTarget 読み込んだスクリプトをどの要素に`appendChild`するか
 * @returns
 */
export const loadExternalScript = async (
	src: string,
	appendTarget: "body" | "head" = "body",
	className: string = "",
	attributes: Partial<HTMLScriptElement> = {}
): Promise<ScriptLoadedType> => {
	// 重複確認
	if (document.querySelector(`script[src="${src}"]`)) {
		console.log(`スクリプトファイルはすでに読み込まれています: ${src}`);
		return ScriptLoadedType.LOADED;
	}

	// スクリプトが読み込まれていない場合
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = src;
		script.className = className;

		Object.assign(script, attributes);

		script.onload = () => {
			console.log(`スクリプトファイルの読み込みに成功しました: ${src}`);
			resolve(ScriptLoadedType.FIRST_TIME);
		};

		script.onerror = () => {
			console.error(`スクリプトファイルの読み込みに失敗しました: ${src}`);
			reject(new Error(`スクリプトファイルの読み込みに失敗しました: ${src}`));
		};

		document[appendTarget].appendChild(script);
	});
};
