import { CreditAgency } from "./enum";

/** 初期化成功時のZEUSのレスポンス */
export interface CreditCardZeusInitData {
	agency: CreditAgency.ZEUS;
	client_ip: string;
}

export enum ZeusGetTokenAction {
	NEW_CARD = "newcard",
	QUICK = "quick"
}

interface ZeusGetTokenRequestCommon {
	request: {
		$: {
			/** 固定値：`token` */
			service: "token";
			/**
			 * トークン取得種別
			 *
			 * - `newcard`：初回、毎回決済
			 * - `quick`：継続決済
			 */
			action: ZeusGetTokenAction;
		};
		/** 認証情報 */
		authentication: {
			/** ipコード */
			clientip: string;
		};
		/** カード情報 */
		card: {
			/** セキュリティーコード */
			cvv?: string;
		};
	};
}

/** zeus：カード継続利用の場合のトークン取得リクエスト（xmlに変換） */
export interface ZeusGetTokenQuickRequest extends ZeusGetTokenRequestCommon {
	request: ZeusGetTokenRequestCommon["request"] & {
		$: ZeusGetTokenRequestCommon["request"]["$"] & {
			action: ZeusGetTokenAction.QUICK;
		};
	};
}

/** zeus：トークン取得リクエスト（xmlに変換） */
export interface ZeusGetTokenNewCardRequest extends ZeusGetTokenRequestCommon {
	request: ZeusGetTokenRequestCommon["request"] & {
		$: ZeusGetTokenRequestCommon["request"]["$"] & {
			action: ZeusGetTokenAction.NEW_CARD;
		};
		/** カード情報 */
		card: ZeusGetTokenRequestCommon["request"]["card"] & {
			/** カード番号 */
			number: number | string;
			/** 有効期限 */
			expires: {
				/** 年 */
				year: number | string;
				/** 月 */
				month: number | string;
			};
			/** 利用カード名義 */
			name: string;
		};
	};
}

/** zeus：トークン取得完了時のレスポンス */
export interface ZeusGetTokenSuccessResponse {
	result: {
		/** トークン取得に成功したかどうか */
		status: "success";
		/** トークン */
		token_key: string;
		/** マスク済みのセキュリティーコード */
		masked_cvv: "****" | "***";
		/** マスク済みのカード番号 */
		masked_card_number: string;
		/** 有効期限（月） */
		card_expires_month: string;
		/** 有効期限（年） */
		card_expires_year: string;
		/** 利用カード名義 */
		card_name: string;
	};
}

/** zeus：トークン取得失敗時のレスポンス */
export interface ZeusGetTokenFailureResponse {
	result: {
		/** トークン取得に成功したかどうか */
		status: "invalid" | "maintenance";
		/** エラーコード */
		code: string;
	};
}

/** zeus：トークン取得レスポンスの共通型
 *
 * `response.attribute.status`の値によって内容が変わる
 */
export interface ZeusGetTokenResponse {
	response: {
		attribute: {
			/** 固定値：`token` */
			service: "token";
			/**
			 * トークン取得種別
			 *
			 * - `newcard`：初回、毎回決済
			 * - `quick`：継続決済
			 */
			action: ZeusGetTokenAction;
		};
	} & (ZeusGetTokenSuccessResponse | ZeusGetTokenFailureResponse);
}
