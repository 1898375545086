import { nanoid } from "nanoid";
import {
	CardInfo,
	CreditCardJsInitResponse,
	GetTokenOnSuccess,
	GetTokenOnFailure,
	PaymentSystemReturnStatus,
	GetTokenSuccessResponse,
	GetTokenFailureResponse
} from "../../../types/v1/credit_card";
import {
	CreditCardGmoInitData,
	GetTokenCallbackFailureObject,
	GetTokenCallbackObject,
	GetTokenCallbackSuccessObject,
	Multipayment,
	MultipaymentCard
} from "../../../types/v1/credit_card/gmo";
import { formatExpire } from "../../common/formatExpire";
import { loadExternalScript } from "../../common/loadExternalScript";
import { gmoEndpoints } from "../../endpoint/gmo";
import { getTokenError } from "../error/tokenError";
import { maskCardNumber, maskSecurityCode } from "../util";

declare global {
	interface Window {
		Multipayment: Multipayment;
	}
}

/**
 * トークン取得結果コードが成功系かどうかを確認する型ガード
 * @param res トークン取得コールバックオブジェクト
 * @returns returnCode === "000"の時、`GetTokenCallbackSuccessObject`型とみなす
 */
const isGetTokenCallbackSuccessObject = (
	res: GetTokenCallbackObject
): res is GetTokenCallbackSuccessObject => {
	return res.resultCode === "000";
};

/**
 * カード情報を設定を元にtokenを取得する
 *
 * `window.Multipayment.getToken`関数はコールバック関数でしか結果を取得できないため、Promiseを生成し、コールバック内で`resolve`か`reject`しています。
 * 必ず、そのような実装をキープしてください
 * @param cardInfo カード情報
 * @param settings 決済システム設定情報
 * @param cardDetail 決済システムサーバーから取得した登録済みカード詳細情報, cardInfo.card_idが存在する場合のみ
 * @param onSuccess token発行成功時のコールバック
 * @param onFailure token発行失敗時のコールバック
 * @returns トークン関連情報
 */
export const creditGmoGetToken = async (
	cardInfo: CardInfo,
	settings: CreditCardJsInitResponse<CreditCardGmoInitData>,
	onSuccess?: GetTokenOnSuccess,
	onFailure?: GetTokenOnFailure
): Promise<GetTokenSuccessResponse | GetTokenFailureResponse> => {
	// gmo提供jsファイル（MPToken.js）の読み込み
	await loadExternalScript(gmoEndpoints.mpToken[settings.environment], "head", "mulpay-js");

	window.Multipayment?.init(settings.ship_id);

	const format = formatExpire({
		year: { number: cardInfo.expires.year, length: 2 },
		month: { number: cardInfo.expires.month, padding: true }
	});

	const multipaymentCard: MultipaymentCard = {
		cardno: cardInfo.number,
		expire: `${format.year}${format.month}`,
		holdername: cardInfo.name,
		...(settings.is_use_card_verification_value && {
			securitycode: cardInfo.security_code
		})
	};

	return new Promise((resolve, reject) => {
		/**
		 * トークン取得後のコールバック関数
		 * @param res トークン取得結果
		 */
		const callback = (res: GetTokenCallbackObject) => {
			console.log(res);

			/**
			 * トークン取得成功時処理
			 * （res.resultCode === "000"）
			 * @param successRes 成功時レスポンス
			 */
			const successCallback = (successRes: GetTokenCallbackSuccessObject) => {
				const successResponse: GetTokenSuccessResponse = {
					status: PaymentSystemReturnStatus.SUCCESS,
					token: successRes.tokenObject.token,
					masked_security_code: maskSecurityCode(cardInfo.security_code),
					number: maskCardNumber(cardInfo.number),
					name: cardInfo.name,
					expires: { ...cardInfo.expires },
					brand: cardInfo.brand
				};

				window.LeghornPayment.card = successResponse;
				if (onSuccess) onSuccess(successResponse);

				resolve(successResponse);
			};

			/**
			 * トークンん取得失敗時処理
			 * @param failureRes 失敗時レスポンス
			 */
			const failureCallback = (failureRes: GetTokenCallbackFailureObject) => {
				const failureResponse: GetTokenFailureResponse = {
					status: PaymentSystemReturnStatus.FAILURE,
					errors: getTokenError([failureRes.resultCode], settings)
				};

				window.LeghornPayment.card = failureResponse;

				if (onFailure) onFailure(failureResponse);

				reject(failureResponse);
			};

			if (isGetTokenCallbackSuccessObject(res)) {
				successCallback(res);
			} else {
				failureCallback(res);
			}
		};

		if (cardInfo.card_id) {
			// 登録済みカードの場合はトークンを発行しなくていいのでこれで
			const cardIdSuccessResponse: GetTokenSuccessResponse = {
				status: PaymentSystemReturnStatus.SUCCESS,
				token: `${nanoid()}_dummy`,
				masked_security_code: maskSecurityCode(cardInfo.security_code),
				number: maskCardNumber(cardInfo.number),
				name: cardInfo.name,
				expires: { ...cardInfo.expires },
				brand: cardInfo.brand
			};

			window.LeghornPayment.card = cardIdSuccessResponse;
			resolve(cardIdSuccessResponse);
		} else {
			// トークン取得実行
			window.Multipayment.getToken(multipaymentCard, callback);
		}
	});
};
