// TODO: 流石に分ける、、、？
// 分ける場合はバックエンドと同じ、処理/共通 || 決済代行 の形式に

import { CreditCardTypeCardBrandId } from "credit-card-type/dist/types";
import { CreditAgency, ThreeDomainSecureAuthType } from "./enum";
import { CreditCardYamatoInitData } from "./yamato";
import { CreditCardZeusInitData } from "./zeus";
import { CreditCardGmoInitData } from "./gmo";

/** 決済代行が投げてきたエラーコード */
export type Code = string | number;

export enum PaymentSystemReturnStatus {
	SUCCESS = "success",
	FAILURE = "failure"
}

export type PaymentSystemError = {
	/** 決済システムエラーコード */
	code: string;
	/** エラー箇所 */
	item: string;
	/** エラー詳細 */
	description: string;
	/** 決済システムが投げてきた元のエラーコード */
	origin_code?: Code;
};

export interface CreditCardError extends Partial<Record<CreditAgency, Code[]>> {
	/** 決済システムエラーコード */
	code: string;
	/** エラー箇所 */
	item: string;
	/** エラー詳細 */
	description: string;
}

/** 配信jsのエラーreturn定義 */
export interface PaymentSystemErrorObject {
	/** 処理結果 `failure`  */
	status: PaymentSystemReturnStatus.FAILURE;
	/** エラー内容 */
	errors: PaymentSystemError[];
}

// ------------------------------------------------------------ //
// 登録済みクレカ取得
// ------------------------------------------------------------ //

/** マスク済みのカード情報 */
export interface CardNumber {
	/** 上２桁 */
	prefix: number | string;
	/** 下４桁 */
	suffix: number | string;
	/** 上２桁、下４桁の間のマスク値 */
	masked: string;
}
/** カード有効期限 */
export interface CardExpires {
	/** 年 */
	year: number | string;
	/** 月 */
	month: number | string;
}

interface CreditCard {
	id: string;
	name: string;
	number: CardNumber;
	expires: CardExpires;
	/** カードブランド */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	brand: CreditCardTypeCardBrandId | string;
	last_used_at: string;
}

/** 保存済みクレカ一覧取得リクエスト */
export interface GetCreditCardRequest {
	customer_id: string;
}

/** 保存済みクレカ一覧レスポンス */
export interface GetCreditCardsResponse {
	customer_id: string;
	cards: CreditCard[];
}

/** 保存済みクレカ詳細レスポンス */
export interface CreditCardDetailCommon extends CreditCard {
	created_at: string;
	updated_at: string;
	customer_id: string;
	agency: CreditAgency;
}

/** 決済代行がzeusの時に作られたカードのカード情報詳細 */
export interface ZeusCreditCardDetail extends CreditCardDetailCommon {
	agency: CreditAgency.ZEUS;
	send_id: string;
	send_point: string;
}

/** 決済代行がヤマトの時に作られたカードのカード情報詳細 */
export interface YamatoCreditCardDetail extends CreditCardDetailCommon {
	agency: CreditAgency.YAMATO_WEB_COLLECT;
	member_id: string;
	auth_key: string;
	last_credit_date: string;
}

/** クレカ情報詳細 */
export type CreditCardDetail = ZeusCreditCardDetail | YamatoCreditCardDetail;

/** 引数にカードidがあった場合のレスポンス */
export type GetCreditCardResponse = CreditCard | undefined;

/** クレカ取得成功return */
export type GetContinuationCardsSuccess = (GetCreditCardsResponse | GetCreditCardResponse) & {
	status: PaymentSystemReturnStatus.SUCCESS;
};

/** クレカ取得失敗return */
export interface GetContinuationCardsFailure extends PaymentSystemErrorObject {}

/** クレカ取得return */
type GetContinuationCardsReturn = GetContinuationCardsSuccess | GetContinuationCardsFailure;

export type GetContinuationCards = (
	/** 認証キー */
	apiKey: string,
	/** 認証シグネチャ, 認証key, valueを結合してsha256でハッシュ化して大文字にしたやつ */
	authValue: string,
	/** 検索対象の顧客id */
	customerId: string,
	/** 決済システムが発行したクレジットカード固有のid */
	cardId?: string
) => Promise<GetContinuationCardsReturn>;

export interface GetPostOrPathCardResultResponse {
	status: PaymentSystemReturnStatus.SUCCESS;
	card: CreditCardDetail;
}

export type GetPathCardResult = (
	/** 認証キー */
	apiKey: string,
	/** api認証シグネチャ */
	authValue: string,
	/** 3Dセキュア認証用トランザクションid */
	id: string,
	/** 3Dセキュア認証用トランザクションidの発行時間（文字列） */
	returnAt: string,
	/** 変更対象のカードid */
	cardId: string
) => Promise<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>;

export type GetPostCardResult = (
	/** 認証キー */
	apiKey: string,
	/** api認証シグネチャ */
	authValue: string,
	/** 3Dセキュア認証用トランザクションid */
	id: string,
	/** 3Dセキュア認証用トランザクションidの発行時間（文字列） */
	returnAt: string
) => Promise<GetPostOrPathCardResultResponse | PaymentSystemErrorObject>;

// ------------------------------------------------------------ //
// トークン取得
// ------------------------------------------------------------ //

export interface TokenError extends Partial<Record<CreditAgency, Code[]>>, PaymentSystemError {}

/** クレカ初期化時のリクエスト */
export interface CreditCardJsInitRequest {
	key: string;
	auth_value: string;
	use_payment: "CREDIT_CARD";
}

/** クレカの稼働モード（接続先） */
export type CreditEnvironment = "TEST" | "PRODUCTION";

export type AgencySettings =
	| CreditCardYamatoInitData
	| CreditCardZeusInitData
	| CreditCardGmoInitData;

/** 初期化成功時の共通レスポンス */
export type CreditCardJsInitResponse<T = AgencySettings> = T & {
	status: PaymentSystemReturnStatus.SUCCESS;
	/** 稼働モード（テスト環境環境の場合`TEST`） */
	environment: CreditEnvironment;
	/** セキュリティーコードを利用する場合`true` */
	is_use_card_verification_value: boolean;
	/** 3Dセキュアを利用する場合`true` */
	is_use_three_domain_secure: boolean;
	/** 初期化時の引数に渡されたAPI認証キー（引数のままreturn） */
	api_key: string;
	/** 初期化時の引数に渡されたAPI認証シグネチャ（引数のままreturn） */
	auth_value: string;
};

/** 初期化失敗時のリターン */
export interface JsInitError extends PaymentSystemErrorObject {}

/** クレカのjs初期化関数のリターン */
export type JsInitReturn = CreditCardJsInitResponse<AgencySettings> | JsInitError;

/** クレカ初期化関数 */
export type CreditCardInit = (apiKey: string, authValue: string) => Promise<JsInitReturn>;

// ------------------------------------------------------------------ //

/** トークン取得時のカード情報 */
export interface CardInfo {
	/** 登録済みクレカid, 指定があった場合、登録済みクレカでトークンを取得する */
	card_id?: string;
	/** セキュリティーコード */
	security_code?: string;
	/** 利用カード名義 */
	name: string;
	/** カード番号 */
	number: number | string;
	/** 有効期限 */
	expires: CardExpires;
	/** カードブランド */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	brand: CreditCardTypeCardBrandId | string;
	/** カード登録、もしくは継続課金に利用する場合true */
	// is_continuation?: boolean;
}

/** トークン取得成功時の返り値 */
export interface GetTokenSuccessResponse {
	status: PaymentSystemReturnStatus.SUCCESS;
	/** トークン */
	token: string;
	/** マスク済みのセキュリティーコード */
	masked_security_code: string | undefined;
	/** マスク済みのカード番号 */
	number: CardNumber;
	/** 利用カード名義 */
	name: string;
	/** 有効期限 */
	expires: CardExpires;
	/** カードブランド */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	brand: CreditCardTypeCardBrandId | string;
}

/** トークン取得失敗時の返り値 */
export interface GetTokenFailureResponse extends PaymentSystemErrorObject {}

/** トークン取得成功時のコールバック */
export type GetTokenOnSuccess = (res: GetTokenSuccessResponse) => void;
/** トークン取得失敗時のコールバック */
export type GetTokenOnFailure = (res: GetTokenFailureResponse) => void;
/** トークン取得時のオプション */
export interface GetTokenOptions {
	/** 3Dセキュアを利用しない仮カード登録時に`true`を指定 */
	isProvisionalCard: boolean;
}
/** トークン取得関数の定義 */
export type GetToken = (
	/** カード情報 */
	card: CardInfo,
	/** 認証キー */
	apiKey: string,
	/** 認証シグネチャ, 認証key, valueを結合してsha256でハッシュ化して大文字にしたやつ */
	authValue: string,
	/** トークン取得成功時のコールバック */
	onSuccess?: GetTokenOnSuccess,
	/** トークン取得失敗時のコールバック */
	onFailure?: GetTokenOnFailure,
	/** トークン取得時のオプション */
	options?: GetTokenOptions
) => Promise<GetTokenSuccessResponse | GetTokenFailureResponse>;

// ------------------------------------------------------------ //
// 決済登録
// ------------------------------------------------------------ //
/** 決済登録成功レスポンス */
export interface PaymentRegistrationSuccessResponse {
	/** 決済登録結果 */
	status: PaymentSystemReturnStatus.SUCCESS;
	/**
	 * 3dセキュア認証画面表示に必要なurlやhtml
	 * 利用中の決済代行によってだいぶ、内容が変わる
	 */
	three_domain_secure_auth_url: string;
	/** 決済システムで発行したオーソリまで有効なトランザクションの一意キー */
	transaction_id: string;
	/** 決済システムがトランザクションキーを発行した時の日時 YYYYMMDDhhmmss 形式の文字列 */
	return_at: string;
	/** 利用中の決済代行 */
	agency: CreditAgency;
}

export type PaymentRegistrationSuccessZeusResponse = PaymentRegistrationSuccessResponse & {
	agency: CreditAgency.ZEUS;
	xid: string;
};

export type PaymentRegistrationSuccessYamatoResponse = PaymentRegistrationSuccessResponse & {
	agency: CreditAgency.YAMATO_WEB_COLLECT;
};

export type PaymentRegistrationSuccessGmoResponse = PaymentRegistrationSuccessResponse & {
	agency: CreditAgency.GMO;
	access_id: string;
};

/** 決済登録失敗レスポンス */
export interface PostPaymentRegistrationFailureResponse extends PaymentSystemErrorObject {}

/** 決済登録レスポンス */
export type PostPaymentRegistrationResponse =
	| PaymentRegistrationSuccessZeusResponse
	| PaymentRegistrationSuccessYamatoResponse
	| PaymentRegistrationSuccessGmoResponse
	| PostPaymentRegistrationFailureResponse;

// ------------------------------------------------------------ //
// 3Dセキュア認証実施関数
// ------------------------------------------------------------ //
/** 決済システムからの3Dセキュア完了WebSocket通知 */
export interface DoThreeDomainSecureAuthIncomingMessage {
	transaction_id: string;
	return_at: string;
}

/** 3Dセキュア認証結果レスポンス */
export interface DoThreeDomainSecureAuthReturn {
	status: PaymentSystemReturnStatus.SUCCESS;
	transaction_id: string;
	return_at: string;
}

/** 3Dセキュア認証結果失敗レスポンス */
export interface DoThreeDomainSecureAuthFailureReturn extends PaymentSystemErrorObject {}

/** 3Dセキュア実行関数 */
export type DoThreeDomainSecureAuthOnSuccess = (res: DoThreeDomainSecureAuthReturn) => void;
export type DoThreeDomainSecureAuthOnFailure = (errors: PaymentSystemError[]) => void;

export type DoThreeDomainSecureAuth = (
	/**
	 * 決済登録結果のレスポンス内容
	 *
	 * 対向システムのバックエンドが受け取った決済システムからのレスポンスをそのまま渡す
	 * `agency`の値によって内容が変わる
	 */
	res: PostPaymentRegistrationResponse,
	/**	3ds認証成功時のコールバック（認証自体の成否ではなく、処理が成功したかどうかのこと） */
	onSuccess?: DoThreeDomainSecureAuthOnSuccess,
	/** 3ds認証失敗時のコールバック（認証自体の成否ではなく、処理が成功したかどうかのこと） */
	onFailure?: DoThreeDomainSecureAuthOnFailure,
	options?: {
		/** 3Dセキュア認証画面の表示方法 */
		authType?: ThreeDomainSecureAuthType;
		/** `authType === "REDIRECT"`の場合に、認証完了後遷移させるリダイレクト先URL */
		redirectUrl?: string;
	},
	settings?: JsInitReturn
) => Promise<DoThreeDomainSecureAuthReturn | DoThreeDomainSecureAuthFailureReturn>;

// ------------------------------------------------------------ //

/** 設定取得関数 */
export type GetSettings = () => JsInitReturn;

/** クレカ用配信jsの提供関数定義 */
export interface CreditCardJs {
	urls: Record<string, string | undefined>;
	card: GetTokenSuccessResponse | GetTokenFailureResponse;
	init: CreditCardInit;
	getContinuationCards: GetContinuationCards;
	getToken: GetToken;
	getSettings: GetSettings;
	doThreeDomainSecureAuth: DoThreeDomainSecureAuth;
	getPathCardResult: GetPathCardResult;
	getPostCardResult: GetPostCardResult;
}
