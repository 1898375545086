import CryptoJS from "crypto-js";

/**
 * 引数の文字列を順に結合し、SHA256でハッシュ化して返す
 * @param caseOption SHA256変換後、英字を大文字もしくは小文字のどちらにするか
 * @param args 結合する文字列配列（可変長）
 * @returns SHA256でハッシュ化された文字列
 */
export const generateHash = (caseOption: "lower" | "upper", ...args: Array<string | number>) => {
	// 引数を順番に結合
	const combinedString = args.join("");

	let hashHex = CryptoJS.SHA256(combinedString).toString(CryptoJS.enc.Hex);

	// 大文字、小文字、変換しないオプションを適用
	switch (caseOption) {
		case "upper":
			hashHex = hashHex.toUpperCase();
			break;
		case "lower":
			break;
	}

	return hashHex;
};
